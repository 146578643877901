<template>
  <div class="tourism-carousel_unit">
    <div class="bg-white tourism__slider-unit">
      <div class="category__bg @@class-bg">
        <CategoryShare
          :dealId="deal.dealId"
          :rowId="deal.rowId"
        ></CategoryShare>
        <CategoryStar></CategoryStar>

        <div class="tourism-category__place">
          <div class="tourism-category__image-wrapper">
            <img
              class="tourism-category__image"
              :src="generateDealImagePath(deal) || '/assets/img/rome2.png'"
              alt
            />
          </div>
          <b class="tourism-category__place-name">{{
            deal.destinationName ? deal.destinationName : deal.rowName
          }}</b>
        </div>
      </div>

      <div class="category__info tourism-category__info">
        <p class="fs14">
          <b v-if="deal.type == 'ORGANIZED_TOUR'">{{
            $t('Organized tour to {DES} on a variety of dates', {
              DES: deal.destinationName,
            })
          }}</b>
          <b v-else>{{
            $t('Vacation packages to {DES} on a variety of dates', {
              DES: deal.destinationName,
            })
          }}</b>
        </p>
        <p v-if="deal.type == 'ORGANIZED_TOUR'" class="fs14">
          {{ $t('The price shown is for an organized tour on dates') }}
        </p>
        <p v-else class="fs14">
          {{ $t('The price shown is for a package deal on dates') }}
        </p>
        <p class="fs16">
          <b
            >{{ deal.departureDate | formatDate }}-{{
              calcEndDate(deal.departureDate, deal.dealLength)
            }}</b
          >
        </p>
        <rate-star :starNumber="deal.hotelRating * 1"></rate-star>
        <div
          v-if="deal.dealPricePerPerson || deal.price"
          class="category__price-wrapper"
        >
          <span class="category__price">{{ $t('Price') }}: &nbsp;</span>
          <span class="direction-right font-weight-bold">
            <b class="category__current">{{ $t(deal.currency) }}</b>
            <b class="category__price-value">{{
              deal.dealPricePerPerson ? deal.dealPricePerPerson : deal.price
            }}</b>
          </span>
        </div>
        <router-link
          :to="{
            name: user_type_logged_in == 'EndUser' ? 'EndUserTourism' : 'Tourism',
            query: {'id': deal.destination_id, 'type': deal.type}
          }"
          @click.native="$emit('getDealsFromIdType', {'id': deal.destination_id, 'type': deal.type})"
          class="link category__button tourism-category__button color-white"
        >
          {{ $t('Find dates') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from "../RateStar";
import CategoryShare from "../CategoryShare";
import CategoryStar from "../tourism/CategoryStar" 

import tourismMixin from '../../utility/tourism_mixin'

export default {
  name: 'CarouselUnit',
  mixins: [tourismMixin],
  components: {
    RateStar,
    CategoryShare,
    CategoryStar
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style lang="scss">
.tourism-carousel_unit {
  padding: 8px;
  .tourism__slider-unit {
    box-shadow: 0 3px 6px #ddd;
  }
}
</style>
