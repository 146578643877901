<template>
  <main class="home blank-aside-js page_bg_grey pb-20" :class = "(isOpenAside=='true')?'blank-aside':''">
    <banner-section location="homepage-top"/>
    <section class="offers p-relative">
      <section class="communication-list">
        <router-link
          :to="{
            name: 'Communication'
          }"
          tag="a"
          exact
          class="text-decoration-none"
        >
          <h2 class="title communication-list__title">{{ $t("Mobile Prepaid") }}</h2>
        </router-link>
        <div v-if="prepaidHomepageProducts" class="communication-list__container">
          <div v-for="(product_item, index) in prepaidHomepageProducts" class="communication-list__item" :key="index">
            <router-link :to="{name: 'CommunicationProduct', params: {id: product_item.id, supplier_id: product_item.supplier_id }}" tag="a" class="link communication-list__link" exact>
              <div class="communication-list__img-wrapper" :style="{'background-color': product_item.supplier_image_background_color}">
                <img v-if="product_item.supplier_image" class="communication-list__img" :src="product_item.supplier_image" alt />
              </div>

              <div class="communication-list__info">
                <div class="communication-list__product-name">{{ product_item.name }}</div>

                <div class="communication-list__price">
                  <b class="communication-list__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                  <b class="communication-list__current">{{ $t("NIS") }}</b>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>{{ $t("No data") }}</div>
      </section>
      <section class="communication-list">
        <router-link
          :to="{
            name: 'Payment'
          }"
          tag="a"
          exact
          class="text-decoration-none"
        >
          <h2 class="title communication-list__title">{{ $t("Payments") }}</h2>
        </router-link>
        <div v-if="billsHomepageProducts" class="communication-list__container">
          <div v-for="(product_item, index) in billsHomepageProducts" class="communication-list__item" :key="index">
            <router-link :to="{name: 'SupplierPayment', params: {supplier_id: product_item.supplier_id }}" tag="a" class="link communication-list__link" exact>
              <div class="communication-list__img-wrapper" :style="{'background-color': product_item.supplier_image_background_color}">
                <img v-if="product_item.supplier_image" class="communication-list__img" :src="product_item.supplier_image" alt />
              </div>

              <div class="communication-list__info">
                <div class="communication-list__product-name">{{ product_item.name }}</div>

                <div v-if="product_item.description" class="payment-list__discription" v-html="product_item.description"></div>
                <!-- <div class="communication-list__price">
                  <b class="communication-list__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                  <b class="communication-list__current">{{ $t("NIS") }}</b>
                </div> -->

                 <!-- <div>
            <input class="input authorisation__checkbox" id="remember" type="checkbox" />
            <label
              class="label  authorisation__remember-me"
              for="remember"
            >{{ $t("Remember me") }}</label>
          </div> -->
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>{{ $t("No data") }}</div>
      </section>
      <section class="communication-list">
        <router-link
          :to="{
            name: 'GiftCard'
          }"
          tag="a"
          exact
          class="text-decoration-none"
        >
          <h2 class="title gift-card__title">{{ $t("Gift Card") }}</h2>
        </router-link>

        <div v-if="giftCardHomepageProducts" class="communication-list__container">
          <div v-for="(product_item, index) in giftCardHomepageProducts" class="communication-list__item" :key="index">
            <router-link :to="{name: 'GiftCardProduct', params: {id: product_item.id, supplier_id: product_item.supplier_id }}" tag="a" class="link communication-list__link" exact>
              <div class="communication-list__img-wrapper" :style="{'background-color': product_item.supplier_image_background_color}">
                <img v-if="product_item.supplier_image" class="communication-list__img" :src="product_item.supplier_image" alt />
              </div>

              <div class="communication-list__info">
                <div class="communication-list__product-name">{{ product_item.name }}</div>

                <div class="communication-list__price">
                  <b class="communication-list__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                  <b class="communication-list__current">{{ $t("NIS") }}</b>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>{{ $t("No data") }}</div>
      </section>
      <section class="communication-list">
        <router-link
          :to="{
            name: 'Game'
          }"
          tag="a"
          exact
          class="text-decoration-none"
        >
          <h2 class="title games__title">{{ $t("Games") }}</h2>
        </router-link>

        <div v-if="gameHomepageProducts" class="communication-list__container">
          <div v-for="(product_item, index) in gameHomepageProducts" class="communication-list__item" :key="index">
            <router-link :to="{name: 'GameProduct', params: {id: product_item.id, supplier_id: product_item.supplier_id }}" tag="a" class="link communication-list__link" exact>
              <div class="communication-list__img-wrapper" :style="{'background-color': product_item.supplier_image_background_color}">
                <img v-if="product_item.supplier_image" class="communication-list__img" :src="product_item.supplier_image" alt />
              </div>

              <div class="communication-list__info">
                <div class="communication-list__product-name">{{ product_item.name }}</div>

                <div class="communication-list__price">
                  <b class="communication-list__price-value">{{ ' ' + product_item.price + ' ' }}</b>
                  <b class="communication-list__current">{{ $t("NIS") }}</b>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>{{ $t("No data") }}</div>
      </section>
      <loading :active.sync="productLoading" :can-cancel="false" :is-full-page="false"></loading>
    </section>
    
    <section class="p-relative tourism-block">
      <div class="tourism-slick_container"
        v-for="(deals, key) in filteredCheapestDeals"
        :key="key"
      >
        <section
          class="tourism__cheapest_nofshon"
          v-if="deals.length"
        >
          <h2 class="title title__h2 tourism__title">
            {{ key=='CHARTER' ? $t("Cheapest flights") : $t(key) }}
          </h2>

          <VueSlickCarousel
            v-if="key == 'CHARTER'"
            v-bind:slidesToShow="itemPerSlide"
            class="cus-slick-carousel tourism-carousel"
            :class="deals.length<4 ? sliderDirection : ''"
            :rtl="sliderDirection=='rtl' ? true : false"
          >
            <div class="px-4" 
              v-for="(deal, index) in deals"
              :key="'f'+index"
            >
              <FlightTourismItem :deal="deal">
                <!-- <p class="fs14 text-center" slot="title">
                  Flights to <b>{{ deal.dealDestination }}</b>
                </p> -->
                <button 
                  class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
                  slot="actionPart" 
                  @click="openFullDetailModal2(deal)"
                >
                  <img src="/assets/img/icons/info-white.svg" alt="" class="">
                  {{ $t( "Details" ) }}
                </button>
                <router-link
                  :to="{
                    name: 'FlightDestinationDates',
                  }"
                  slot="routerBlock" 
                >
                  <button class="primary-btn small-btn fs16 bg-3">
                    {{ $t( "Explore more Dates" ) }}
                  </button>
                </router-link>
              </FlightTourismItem>
            </div>
          </VueSlickCarousel>
          <VueSlickCarousel
            v-else
            v-bind:slidesToShow="itemPerSlide"
            class="cus-slick-carousel tourism-carousel"
            :class="deals.length<4 ? sliderDirection : ''"
            :rtl="sliderDirection=='rtl' ? true : false"
          >
            <CarouselUnit 
              :deal="deal" 
              v-for="(deal, index) in deals"
              :key="index"
              @getDealsFromIdType="getDealsFromIdType"
              
            >
            </CarouselUnit>
          </VueSlickCarousel>
        </section>
      </div>
      <loading :active.sync="cheapestDealsAllLoading" :can-cancel="false" :is-full-page="false"></loading>
    </section>
    <FullDetailModal2 ref="fullDetailModal2Ref"></FullDetailModal2>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CarouselUnit from "../components/tourism/CarouselUnit";
import BannerSection from "../components/BannerSection";
import FlightTourismItem from "@/components/tourism/flight/FlightTourismItem.vue";
import FullDetailModal2 from "../components/tourism/flight/FullDetailModal2.vue";

export default {
  name: "Dashboard",
  components: {
    BannerSection,
    CarouselUnit,
    FlightTourismItem,
    FullDetailModal2
  },
  mounted() {
    // moduleCommunication.init();
    // moduleDropdown.init();
    // moduleEntertainment.init();
    // moduleFavorites.init();
    // moduleFilter.init();
    // moduleGift.init();
    // moduleModalBooking.init();
    // moduleModalDiscount.init();
    // modulePayment.init();
    // moduleRegister.init();
    // moduleTourism.init();
    // moduleTourismProd.init();
    // moduleTrip.init();
  },
  created() {
    if (this.product_homepage_tag_id == null) {
      this.getProductTags();
    }
    if (this.products.length == 0) {
      this.loadHomepageProducts();
    }
    if (this.cheapestDealsData.length == 0) {
      this.getCheapestDealsAll();
    } 
  },
  data: () => {
    return {
      lote: true
    }
  },
  
  computed: {
    ...mapState({
      loading: (state) => state.deal.loading,
      productLoading: (state) => state.product.product_module_loading,
      cheapestDealsAllLoading: (state) => state.deal.cheapestDealsAllLoading,
      products: state => state.product.products,
      product_homepage_tag_id: state => state.product.product_homepage_tag_id,
      cheapestDealsData: (state) => state.deal.cheapestDealsAllData,
    }),
    ...mapGetters('permission', {
      hasPathAccess: 'hasPathAccess'
    }),
    prepaidHomepageProducts() {
      let prepaidHomepageProducts = []

      if (this.product_homepage_tag_id && this.products && this.products.length) {
        for (let product_id in this.products) {
          let product = this.products[product_id]
          if (product.hasOwnProperty('supplier_type') && product.supplier_type == 1
            && product.hasOwnProperty('tags') && product.tags && product.tags.length
            && product.tags.includes(this.product_homepage_tag_id)) {
            prepaidHomepageProducts.push(product)
          }
        }
      }

      return prepaidHomepageProducts
    },
    billsHomepageProducts() {
      let billsHomepageProducts = []

      if (this.product_homepage_tag_id && this.products && this.products.length) {
        for (let product_id in this.products) {
          let product = this.products[product_id]
          if (product.hasOwnProperty('supplier_type') && product.supplier_type == 2
            && product.hasOwnProperty('tags') && product.tags && product.tags.length
            && product.tags.includes(this.product_homepage_tag_id)) {
              billsHomepageProducts.push(product)
          }
        }
      }

      return billsHomepageProducts
    },
    giftCardHomepageProducts() {
      let giftCardHomepageProducts = []

      if (this.product_homepage_tag_id && this.products && this.products.length) {
        for (let product_id in this.products) {
          let product = this.products[product_id]
          if (product.hasOwnProperty('supplier_type') && product.supplier_type == 4
            && product.hasOwnProperty('tags') && product.tags && product.tags.length
            && product.tags.includes(this.product_homepage_tag_id)) {
              giftCardHomepageProducts.push(product)
          }
        }
      }

      return giftCardHomepageProducts
    },
    gameHomepageProducts() {
      let gameHomepageProducts = []

      if (this.product_homepage_tag_id && this.products && this.products.length) {
        for (let product_id in this.products) {
          let product = this.products[product_id]
          if (product.hasOwnProperty('supplier_type') && product.supplier_type == 6
            && product.hasOwnProperty('tags') && product.tags && product.tags.length
            && product.tags.includes(this.product_homepage_tag_id)) {
              gameHomepageProducts.push(product)
          }
        }
      }

      return gameHomepageProducts
    },
    filteredCheapestDeals() {
      let filteredCheapestDeals = {};

      if (Array.isArray(this.cheapestDealsData)) {
        this.cheapestDealsData.forEach(el => {
          let type = el.type;
          if (filteredCheapestDeals[type]) {
            filteredCheapestDeals[type].push(el);
          } else {
            filteredCheapestDeals[type] = [];
            filteredCheapestDeals[type].push(el);
          }
        })
      }
      return filteredCheapestDeals;
    },
  },
  watch: {
    product_homepage_tag_id(product_homepage_tag_id) {
      this.loadHomepageProducts()
    }
  },
  methods: {
    ...mapActions("product", {
      getProducts: 'getProducts',
      getProductTags: 'getProductTags',
    }),
    ...mapActions("deal", {
      getCheapestDealsAll: "getCheapestDealsAll"
    }),
    loadHomepageProducts() {
      if (!this.product_homepage_tag_id) {
        return
      }

      this.getProducts({'params':
        {
          tag: this.product_homepage_tag_id,
          type: [1, 2, 3, 4, 5],
          supplier_type: [1, 2, 4, 6]
        }
      })
    },
    async getDealsFromIdType({id, type}) {
      this.$router.push({ name: 'Tourism', query: { id, type }})
    },
    openFullDetailModal2(deal) {
      this.$refs.fullDetailModal2Ref.openModal(deal);
    }
  },
};
</script>

<style lang="scss">
  /* slick carousel */
  .tourism-carousel .slick-prev, .tourism-carousel .slick-next {
    width: 70px;
  }
  .tourism-carousel .slick-prev {
    left: -80px;
  }
  .tourism-carousel .slick-next {
    right: -80px;
  }
  .tourism-carousel.rtl * {
    direction: rtl !important;
  }
  .tourism-slick_container {
    padding: 0 80px;
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }
 /* slick carousel end */

  .home__list {
    width: 1100px;
  }
  .communication-list__link::before,
  .payment-list__link::before {
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
  }
  .communication-list__link::after,
  .payment-list__link::after {
    top: 50%;
    transform: translateY(-50%);
    left: 34px;
  }
  .communication-list,
  .payment-list {
    flex-basis: calc(25% - 24px);
  }
  .communication-list__title {
    font-size: 18px;
    // background-color: #29ABE2;
    background-color: #002036;
    margin: 0;
    text-align: center;
    color: #fff;
    padding: 10px 0;
  }
  .gift-card__title {
    font-size: 18px;
    // background-color:#E50914;
    background-color:#002036;
    margin: 0;
    text-align: center;
    color: #fff;
    padding: 10px 0;
  }
  .payment-list__title {
    font-size: 18px;
    // background-color:#FCB340;
    background-color:#002036;
    margin: 0;
    text-align: center;
    color: #fff;
    padding: 10px 0;
  }
  .games__title {
    font-size: 18px;
    // background-color:#31A510;
    background-color:#002036;
    margin: 0;
    text-align: center;
    color: #fff;
    padding: 10px 0;
  }
  .home__link-products::before {
    content: "";
    width: 43px;
    height: 43px;
    position: absolute;
    top: 30px;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    background: url('/assets/img/icons/shopping-bag-light.svg') no-repeat;
        background-size: auto;
    background-size: contain;
  }
  .home__link-games::before {
    content: "";
    width: 43px;
    height: 43px;
    position: absolute;
    top: 30px;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    background: url('/assets/img/icons/shopping-bag-light.svg') no-repeat;
        background-size: auto;
    background-size: contain;
  }
  @media screen and (max-width: 767px) {
    .home__link-products::before {
      width: 32px;
      height: 32px;
      top: 10px;
    }
    .home__link-games::before {
      width: 32px;
      height: 32px;
      top: 10px;
    }
    .tourism-carousel .slick-prev, .tourism-carousel .slick-next {
      width: 20px;
    }
    .tourism-carousel .slick-prev {
      left: -15px;
    }
    .tourism-carousel .slick-next {
      right: -15px;
    }
    .cus-slick-carousel .slick-prev::before, 
    .cus-slick-carousel .slick-next::before {
      background-size: contain;
      width: 12px;
      height: 21px;
    }
  }
  
  .ltr-type {
    .payment-list__info {
      padding-left: 20px !important;
      padding-right: 70px !important;
    }
    .payment-list__link::before {
      left: unset;
      right: 25px;
    }
    .payment-list__link::after {
      left: unset;
      right: 40px;
      transform: rotate(180deg) translateY(50%);
    }
    .communication-list__link::before {
      left: unset;
      right: 20px;
    }
    .communication-list__link::after {
      left: unset;
      right: 35px;
      transform: rotate(180deg) translateY(50%);
    }
  }
</style>
<style lang="scss" scoped>
  .offers {
    padding-top: 10px;
    min-height: 300px;
    @media (max-width: 1500px) {
      padding: 0 20px;
    }
  }

  .home .communication-list__img-wrapper {
    height: unset;
    height: 88px;
  }
  .home .communication-list__info {
    margin: 0 !important;
    padding: 12px 15px 0;
    width: calc(100% - 120px);
    box-sizing: border-box;
  }

  .home .payment-list__item {
    height: unset;
    min-height: 125px;
  }
  .home .payment-list__info {
    margin: 0;
    padding-right: 20px;
    padding-left: 70px;
    width: calc(100% - 146px);
    box-sizing: border-box;
  }
  .home .payment-list__img-wrapper {
    height: unset;
    min-height: 125px;
  }
  .communication-list__product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
  }
  .communication-list__price {
    white-space: nowrap;
    overflow: hidden;
    font-size: 20px;
  }
  .tourism-block {
    min-height: 400px;
  }
</style>